import React, { useEffect, useState } from "react"
import Layout from "../../../containers/layout"
import { graphql } from "gatsby"
import products from "../../../data/products.json"
import { Image, Anchor } from "grommet"
import CardListing from "../../../components/CardListing"

var shuffle = function (array) {
  var currentIndex = array.length
  var temporaryValue, randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

const Home = () => {
  const [dataList, setDataList] = useState(
    products.map(e => ({
      thumbnail: e.img,
      title: e.title,
      cta: (
        <Anchor
          target="_blank"
          href={`${e.href}&tag=openfengshui-20`}
          label="Get From Amazon"
        />
      ),
    }))
  )

  useEffect(() => {
    setDataList(shuffle(dataList.slice()))
  }, [])

  return (
    <Layout max="1240px">
      <CardListing dataList={dataList} />
    </Layout>
  )
}

export default Home
