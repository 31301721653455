import React from "react"
import Layout from "../../containers/layout"
import { graphql } from "gatsby"
import products from "../../data/products.json"
import {
  Image,
  Anchor,
  Box,
  Heading,
  Card,
  CardBody,
  CardFooter,
} from "grommet"

const Home = ({ dataList }) => {
  console.log(dataList)
  return (
    <Box direction="row" wrap={true} justify="center">
      {dataList &&
        dataList.map((e, i) => {
          const { thumbnail, title, videoId, cta } = e
          return (
            <Box key={i} pad="small">
              <Card elevation="large" width="small">
                <CardBody height="small">
                  <Image fit="contain" src={thumbnail} a11yTitle="bridge" />
                </CardBody>
                <Box pad={{ horizontal: "medium" }} responsive={false}>
                  <Heading level="6" margin={{ vertical: "medium" }}>
                    {title}
                  </Heading>
                </Box>
                <CardFooter>
                  <Box
                    fill={true}
                    justify="center"
                    direction="row"
                    align="center"
                    gap="small"
                  >
                    {cta}
                  </Box>
                </CardFooter>
              </Card>
            </Box>
          )
        })}
    </Box>
  )
}

export default Home
